import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MEDIA_QUERY, ZINDEX } from '@zola/zola-ui/src/styles/emotion';

type NavHeightBreakdown = {
  announcement: number;
  utility: number;
  secondary: number;
  tertiary: number;
};

const preAuth = {
  mobile: {
    announcement: 0,
    utility: 56,
    secondary: 52,
    tertiary: 0,
  },
  tablet: {
    announcement: 0,
    utility: 56,
    secondary: 52,
    tertiary: 0,
  },
  desktop: {
    announcement: 56,
    utility: 76,
    secondary: 56,
    tertiary: 0,
  },
};

const newPostAuth = {
  mobile: {
    announcement: 0,
    utility: 56,
    secondary: 40,
    tertiary: 52,
  },
  tablet: {
    announcement: 0,
    utility: 56,
    secondary: 40,
    tertiary: 52,
  },
  desktop: {
    announcement: 56,
    utility: 76,
    secondary: 40,
    tertiary: 56,
  },
};

const getNavHeightValue = ({
  navHeightBreakdown,
  showAnnouncementBar,
  showSecondaryData,
  showTertiaryData,
}: {
  navHeightBreakdown: NavHeightBreakdown;
  showAnnouncementBar: boolean;
  showSecondaryData: boolean;
  showTertiaryData: boolean;
  isMultiAnnouncementBar?: boolean;
}) => {
  return (
    (showAnnouncementBar ? navHeightBreakdown.announcement : 0) +
    navHeightBreakdown.utility +
    (showSecondaryData ? navHeightBreakdown.secondary : 0) +
    (showTertiaryData ? navHeightBreakdown.tertiary : 0)
  );
};

type NavContainerProps = {
  isGuest: boolean;
  showAnnouncementBar: boolean;
  showSecondaryData: boolean;
  showTertiaryData: boolean;
};

const getNavHeights = ({
  isGuest,
  showAnnouncementBar,
  showSecondaryData,
  showTertiaryData,
}: NavContainerProps) => {
  const defaultProps = {
    showAnnouncementBar,
    showSecondaryData,
    showTertiaryData,
  };

  // pre-auth
  if (isGuest) {
    return css`
      height: ${getNavHeightValue({
        ...defaultProps,
        navHeightBreakdown: preAuth.mobile,
      })}px;
      ${MEDIA_QUERY.TABLET} {
        height: ${getNavHeightValue({
          ...defaultProps,
          navHeightBreakdown: preAuth.tablet,
        })}px;
      }
      ${MEDIA_QUERY.DESKTOP} {
        height: ${getNavHeightValue({
          ...defaultProps,
          navHeightBreakdown: preAuth.desktop,
        })}px;
      }
    `;
  }
  // post-auth
  return css`
    height: ${getNavHeightValue({
      ...defaultProps,
      navHeightBreakdown: newPostAuth.mobile,
    })}px;
    ${MEDIA_QUERY.TABLET} {
      height: ${getNavHeightValue({
        ...defaultProps,
        navHeightBreakdown: newPostAuth.tablet,
      })}px;
    }
    ${MEDIA_QUERY.DESKTOP} {
      height: ${getNavHeightValue({
        ...defaultProps,
        navHeightBreakdown: newPostAuth.desktop,
      })}px;
    }
  `;
};

export const NavContainer = styled.div<NavContainerProps>`
  z-index: 500;
  transform: none;
  .hidden {
    display: none;
  }
  ${props => getNavHeights(props)};
`;

export const RootNav = styled.div`
  z-index: ${ZINDEX.top};
  position: fixed !important;
  width: 100%;
  top: 0;
`;
