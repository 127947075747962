import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

// Experiment Keys
// export const EXAMPLE_FLAG = 'example-flag'; // https://newamsterdamlabs.atlassian.net/browse/CE-1147

// Seasonal flags
export const HOLIDAY_CARDS = 'holiday-cards';
export const HOLIDAY_SHOPS = 'holiday-shops';
export const CLP_LP_LINKS_TO_SRP = 'marketplace-clp-lp-links-to-srp'; // PE-989 - update vendor footer link to location based SRP link

const FALLBACK_VARIATIONS = {
  [HOLIDAY_CARDS]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_SHOPS]: experimentFlagsHelper.createExperimentFlags(),
  [CLP_LP_LINKS_TO_SRP]: experimentFlagsHelper.createExperimentFlags(),
};

export default experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);

const FALLBACK_VARIATIONS_SSR = {
  // [EXAMPLE_FLAG]: experimentFlagsHelper.createExperimentFlags(),
};

// ts-prune-ignore-next
export const experimentFlagsSSR =
  experimentsFlagsHelperSSR.makeExperimentFlags(FALLBACK_VARIATIONS_SSR);
